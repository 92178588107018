import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import type { ReactNode } from 'react';
import { TrendingMenu } from './TrendingMenu';

export interface StandaloneTrendingMenuProps extends StandaloneComponentProps<typeof TrendingMenu> {
  headline?: ReactNode;
  tags?: {
    content?: ReactNode;
    prefix?: ReactNode;
    url?: string;
  }[];
}

export const StandaloneTrendingMenu: StandaloneComponent<StandaloneTrendingMenuProps> = ({
  headline,
  tags,
  ...props
}) => {
  if (!tags?.length) {
    return null;
  }

  return (
    <TrendingMenu {...props}>
      {headline && <TrendingMenu.Headline>{headline}</TrendingMenu.Headline>}
      {tags?.map(({ content, url, prefix }, index) => (
        <TrendingMenu.Tag
          key={index}
          value={
            <>
              {prefix && <TrendingMenu.Prefix>{prefix}</TrendingMenu.Prefix>} {content}
            </>
          }
          link={{ href: url || '' }}
        />
      ))}
    </TrendingMenu>
  );
};
