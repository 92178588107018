import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';

const Tag = tw.theme({
  extend: TagTheme,
  defaultVariants: {
    size: 'small',
  },
});

const TrendingMenu = tw.theme({
  slots: {
    base: `relative mx-auto my-3 flex w-full snap-x flex-row flex-nowrap items-center gap-3 overflow-y-visible overflow-x-scroll px-6 scrollbar-none`,
    headline: `text-black text-headline-xs`,
    prefix: `mr-1`,
  },
  variants: {
    size: {
      alternative: {
        base: 'mx-auto w-full gap-3 px-0',
      },
    },
  },
});

export default Object.assign(TrendingMenu, { Tag });
